const initState = {
    reports: []
}


const ReportReducer = (state = initState, action) => {

    switch (action.type) {

        case 'LOADING_LOAD_REPORTS':
            return {
                ...state,
                reports: [],
                loading: true
            }

        case 'LOAD_REPORTS_SUCCESS':
            return {
                ...state,
                reports: action.res.reports,
                loading: false
            }

        case 'ADD_REPORT_SUCCESS':
            return {
                ...state,
                report: true
            }

        case 'LOGIN_ADMIN_ERROR':
            return {
                ...state,
                reports: action.res,
            }

        default:
            return state

    }
}

export default ReportReducer;
