import { GetService, UpdateService } from "../../../services/Admin/AdminServices";


export const getUserAction = (url) =>{
    return (dispatch)=>{

        dispatch({type:'LOADING_LOAD_USER'});

        GetService(url).then((res) => {

          if(res.hasOwnProperty('success') && res.success === true){

            dispatch({type:'LOAD_USER_SUCCESS', res});

          }else if(res.hasOwnProperty('success') && res.success===false) {
            dispatch({type:'LOAD_USER_ERROR', res})
          }
        },
        error=>{
            dispatch({type:'CODE_ERROR', error});
        }
        )
    }

}

export const EditUserAction = (data, url) =>{
  return (dispatch)=>{

      dispatch({type:'LOADING_USER_PROFILE'});

      UpdateService(data, url).then((res) => {

          if(res.hasOwnProperty('success') && res.success===true){

              dispatch({type:'LOAD_USER_SUCCESS',res});

          }else if(res.hasOwnProperty('success') && res.success===false) {
              dispatch({type:'UPDATE_PROFILE_ERROR',res})
          }
      },
      error=>{
          dispatch({type:'CODE_ERROR',error});
      }
      )
  }
}
