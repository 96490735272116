import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
// import Errors from "./views/Errors";
// import ComponentsOverview from "./views/ComponentsOverview";
import Reports from "./views/Reports";
import BlogPosts from "./views/BlogPosts";
import { Gaurd } from "./Gaurd";
import Login from "./pages/Login";
import ArticlesList from "./pages/ArticlesList";
import ArticleCreateEdit from './pages/ArticleCreateEdit';
import ArticleCreate from "./pages/ArticleCreate";

const routes = (props) => {
  return (
    <BrowserRouter>

        <Switch>
            <Route  exact path="/login" component={Login} />
          <DefaultLayout>
            <Gaurd  path="/blog-overview" token='admin-token' routeRedirect='/login' component={BlogOverview} />
            <Gaurd  path="/blog-posts" token='admin-token' routeRedirect='/login' component={BlogPosts} />
            <Gaurd  path="/user-profile-lite" token='admin-token' routeRedirect='/login' component={UserProfileLite} />
            <Gaurd  path="/reports" token='admin-token' routeRedirect='/login' component={Reports} />
            <Gaurd  path="/articles" token='admin-token' routeRedirect='/login' component={ArticlesList} />
            <Gaurd  path="/article/:id" token='admin-token' routeRedirect='/login' component={ArticleCreateEdit} />
            <Gaurd  path="/add-new-article" token='admin-token' routeRedirect='/login' component={ArticleCreate} />
          </DefaultLayout>
        </Switch>

    </BrowserRouter>
  );
}

export default routes;
