const initState = {
    user: {},
}

const UserReducer = (state = initState, action) => {

    switch (action.type) {
        case 'LOADING_LOAD_USER':
            return {
                ...state,
                user: 'loading',
            }

        case 'LOAD_USER_SUCCESS':
            return {
                ...state,
                user: action.res.user,
            }

        case 'LOAD_USER_ERROR':
            return {
                ...state,
                user: action.res,
            }

        case 'CODE_ERROR':
            return {
                ...state,
                user: 'there seems to be a problem please refresh your browser',
            }

        default:
            return state

    }
}

export default UserReducer;
