import React from 'react';
import { NavLink } from 'react-router-dom';
import MainFooter from '../components/layout/MainFooter';
import LoginForm from '../views/Auth/LoginForm';


export default function Login(props) {
    return (
        <div className="Connection-Wrapper p-3">
            <div className="Dadupa-Login">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-image">
                                <img src="/assets/images/dadupa-brand.svg" alt="Dadupa Connect" />
                            </div>
                            <LoginForm props={props} />
                        </div>
                    </div>
                </div>
            </div>
            {/* <MainFooter /> */}
        </div>
    );
}
