import React, { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { getArticle, getCategories } from "../store/actions/Articles/ArticlesActions";
import ArticleFormUpdate from "../views/Articles/ArticleFormUpdate";

export default function ArticleCreateEdit({match}) {
  const dispatch = useDispatch();
  const articleId = match.params.id;

  const article = useSelector(state => state.article.article);
  const loading = useSelector(state => state.article.loading);

  useEffect(() => {
    if(articleId) {
      dispatch(getArticle(articleId));
    }
    dispatch(getCategories());
}, [dispatch]);

if(article && loading !== undefined && !loading) {
    return <ArticleFormUpdate article={article}/>
  }else {
    return ''
  }
};
