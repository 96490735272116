import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, FormInput, Button } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { getReports, deleteBlockReport } from "../store/actions/Report/ReportActions";
import ConfirmationModal from "../components/ConfirmationModal";


export default function Reports() {
  const priorityClasses = { "high": "danger", "medium": "warning", "low": "secondary" }
  const baseUrl = process.env.REACT_APP_FRONT_URL;
  const dispatch = useDispatch()

  const [modalOpen, setModalOpen] = useState(false)
  const [selectedReport, setSelectedReport] = useState()
  const [actionType, setActionType] = useState()
  const [contentType, setContentType] = useState()

  const reports = useSelector(state => state.reports.reports)
  const loading = useSelector(state => state.reports.loading)

  console.log(reports)

  const iconClick = (type, report) => {
    setActionType(type)
    setSelectedReport(report)
    setContentType(report.provider)
    setModalOpen(true)
  }

  const action = () => {
    let data = {
      'url': 'report/' + actionType,
      'report_id': selectedReport.id,
      'provider_id': selectedReport.provider_id,
      'provider': selectedReport.provider,
    }
    dispatch(deleteBlockReport(data));
    dispatch(getReports());
  };

  useEffect(() => {
    dispatch(getReports());
  }, [dispatch])

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4 d-flex justify-content-between align-items-center">
        <PageTitle sm="4" title="Reports List" subtitle="Reports" className="text-sm-left" />
        <div className="d-flex">
          <FormInput placeholder="Search" />
          <select className="form-control custom-select ml-2">
            <option value="private">Priority</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">Hight</option>
          </select>
          <select className="form-control custom-select ml-2">
            <option value="private">Blocked</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">Hight</option>
          </select>
          <Button className="ml-2">
            <i className="material-icons">search</i>
          </Button>
        </div>
      </Row>

      {/* Test Table */}
      <Row>
        <Col>
          <Card small className="mb-4 overflow-hidden">
            {/* <CardHeader>
            <h6 className="m-0 text-white">Test Table</h6>
          </CardHeader> */}
            <CardBody className="p-0 pb-3">
              <table className="mb-0 table">
                <thead>
                  <tr>
                    <th className="bt-0"><input type="checkbox" /></th>
                    <th className="bt-0">Priority</th>
                    <th className="bt-0">Content Type</th>
                    <th className="bt-0">Link</th>
                    <th className="bt-0">Description</th>
                    <th className="bt-0">Reporter</th>
                    <th className="bt-0">Author</th>
                    <th className="bt-0">Blocked</th>
                    <th className="text-right bt-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    loading ? <tr><td colSpan={9}><h1>Loading</h1></td></tr>
                      :
                      reports.map((report, index) =>
                        <tr key={index + 1}>
                          <td className="align-middle">
                            <input type="checkbox" />
                          </td>
                          <td className="align-middle">
                            <span className={`badge badge-${priorityClasses[report.priority]} badge-pill text-white`}>{report.priority}</span>
                          </td>
                          <td className="align-middle">
                            <div>{report.provider}</div>
                          </td>
                          <td className="align-middle">
                            <div><a href={baseUrl + report.link} target={"_blank"}>Url</a></div>
                          </td>
                          <td className="align-middle">
                            <div>{report.description}</div>
                          </td>
                          <td className="align-middle">
                            <div className="d-flex align-items-center">
                              <img className="rounded-circle mr-2" width='30' height='30' src={report.owner.avatar}  alt='avatar' />
                              <div className="media-body">
                                {/* <div className="mt-0 d-flex text-inverse">Zachariah</div>
                                <span>User</span> */}
                                <a href={baseUrl + report.reporter} target={"_blank"}>{report.owner.name}</a>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            <div className="d-flex align-items-center">
                              <img className="rounded-circle mr-2" width='30' height='30' src={report.from.avatar} alt='avatar' />
                              <div className="media-body">
                                {/* <div className="mt-0 d-flex text-inverse">Zachariah</div>
                                <span>User</span> */}
                                <a href={baseUrl + report.author} target={"_blank"}>{report.from.name}</a>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            <span>{report.blocked}</span>
                          </td>
                          <td className="align-middle text-right">
                            <button onClick={() => {iconClick("block", report)}} className="text-decoration-none btn btn-link p-1">
                              <i className="fa fa-ban"></i>
                            </button>
                            <button onClick={() => {iconClick("delete", report)}} className="text-decoration-none btn btn-link p-1">
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      )}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ConfirmationModal
        open={modalOpen}
        setter={setModalOpen}
        title={`Are you sure you want to ${actionType} this ${contentType} ?`}
        action={action}
      />
    </Container>
  )
}
