import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from 'react-router-dom';
import ConfirmationModal from "../../components/ConfirmationModal";
import { deleteArticle, getArticles } from "../../store/actions/Articles/ArticlesActions";


export default function ArticlesTable({ articles }) {
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedId, setSelectedId] = useState()

    const dispatch = useDispatch()
    const article = useSelector(state => state.article)

    const action = () => {
        dispatch(deleteArticle({ id: selectedId }))
    }

    return (
        <>
            <table className="mb-0 table">
                <thead>
                    <tr>
                        <th className="bt-0">Title</th>
                        <th className="bt-0">Author</th>
                        <th className="bt-0">Status</th>
                        <th className="bt-0">Visibility</th>
                        <th className="text-right bt-0">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {articles.map((article, index) => (
                        <tr key={index + 1}>
                            <td className="align-middle">
                                <div className="text-inverse">
                                    <Link to={`/article/${article.id}`}>
                                        {article.title}
                                    </Link>
                                </div>
                            </td>
                            <td className="align-middle">
                                <div className="d-flex align-items-center">
                                    {
                                        article.author ?
                                            <img className="rounded-circle mr-2" width='30' height='30' src={article.author.avatar} alt='avatar' />
                                            :
                                            <img className="rounded-circle mr-2" width='30' height='30' src="" alt='avatar' />

                                    }
                                    <div className="media-body">
                                        <div className="mt-0 d-flex text-inverse">
                                            {
                                                article.author ? article.author.name : "null"
                                            }
                                        </div>
                                        {/* <span>User</span> */}
                                    </div>
                                </div>
                            </td>
                            <td className="align-middle">
                                <span className={`badge badge-${article.status === "published" ? "success" : "warning"} badge-pill text-white`}>{article.status}</span>
                            </td>
                            <td className="align-middle">
                                <span className={`badge badge-${article.visibility === "public" ? "success" : "warning"} badge-pill text-white`}>{article.visibility}</span>
                            </td>
                            <td className="align-middle text-right">
                                {/* <button className="text-decoration-none btn btn-link">
                                <i className="fa fa-cog"></i>
                                <i className="fa fa-angle-down ml-2"></i>
                            </button> */}
                                <button onClick={() => { setModalOpen(true); setSelectedId(article.id) }} className="text-decoration-none btn btn-link">
                                    <i className="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    )
                    )}
                </tbody>
            </table>
            <ConfirmationModal
                open={modalOpen}
                setter={setModalOpen}
                title="Are you sure you want to delete this article ?"
                action={action}
            />
        </>
    )
}
