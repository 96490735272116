import React, { useEffect } from "react";

import ArticleForm from "../views/Articles/ArticleForm";
import { useDispatch } from "react-redux";
import { getCategories } from "../store/actions/Articles/ArticlesActions";


export default function ArticleCreate() {

  const formData = {
    title: '',
    body: '',
    attachments: [],
    media: '',
    media_type: '',
    status: "draft",
    visibility: "public",
    categories: [],
    attachements: [],
    media_section: "article",
    type: "",

  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  {
    return <ArticleForm article={formData} type="create"/>
  }
};
