const initState = {
    article: {}
}


const ArticlesReducer = (state = initState, action) => {

    switch (action.type) {

        case 'LOADING_LOAD_ARTICLE':
            return {
                ...state,
                article: {},
                loading: true
            }

        case 'LOAD_ARTICLE_SUCCESS':
            return {
                ...state,
                article: action.res.article,
                loading: false
            }

        case 'EDIT_ARTICLE_SUCCESS':
            return {
                ...state,
                article: action.res.article,
            }

        case 'ADD_ARTICLE_SUCCESS':
        return {
          ...state,
          article: action.res.article,
          created: true
        }

        case 'DELETE_ARTICLE_SUCCESS':
            return {
                ...state,
                deleted: true,
            }

        default:
            return state

    }
}

export default ArticlesReducer;
