import React from "react"

export default function ActionBtn({ value, action = () => { } }) {
    const btnStyle = {
        backgroundColor: "#00CC66",
        width: "100%",
        height: "50px",
        borderRadius: "30px",
        border: "2px solid #00CC66",
        textTransform: "uppercase",
        fontWeight: "700",
        fontSize: "14px",
        textAlign: "center",
        color: "#F3FFF8",
        boxShadow: "none",
        transition: "all .20s cubic - bezier(.65, .05, .36, 1)"
    }

    return <button style={btnStyle} type="submit" name="submit" onClick={action}>
        {value}
    </button>

}