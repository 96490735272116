import { DeleteService, GetService, PostService, UpdateService } from "../../../services/Article/ArticleServices";


export const getArticles = () => {
    return (dispatch) => {

        dispatch({ type: 'LOADING_LOAD_ARTICLES' });

        const tempFilter = {
            by: "",
            search: "",
            categories: [],
            popular: {}
        }
        PostService(tempFilter, "/admin/get").then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {

                dispatch({ type: 'LOAD_ARTICLES_SUCCESS', res });

            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: 'LOAD_ARTICLES_ERROR', res })
            }
        },
            error => {
                dispatch({ type: 'CODE_ERROR', error });
            }
        )
    }
}

export const getCategories = () => {
    return (dispatch) => {

        dispatch({ type: 'LOADING_LOAD_CATEGORIES' });
        GetService("/categories").then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {

                dispatch({ type: 'LOAD_CATEGORIES_SUCCESS', res });

            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: 'LOAD_CATEGORIES_ERROR', res })
            }
        },
            error => {
                dispatch({ type: 'CODE_ERROR', error });
            }
        )
    }
}

export const getArticle = (id) => {
    return (dispatch) => {
        dispatch({ type: 'LOADING_LOAD_ARTICLE' });

        GetService(`/get/${id}`).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {

                dispatch({ type: 'LOAD_ARTICLE_SUCCESS', res });

            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: 'LOAD_ARTICLES_ERROR', res })
            }
        },
            error => {
                dispatch({ type: 'CODE_ERROR', error });
            }
        )
    }
}

export const createArticle = (data) => {
    return (dispatch) => {

        PostService(data, '/create').then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {

                dispatch({ type: 'ADD_ARTICLE_SUCCESS', res });

            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: 'ADD_ARTICLE_ERROR', res })
            }
        },
            error => {
                dispatch({ type: 'CODE_ERROR', error });
            }
        )
    }
}

export const editArticle = (data) => {
    return (dispatch) => {

        UpdateService(data, '/update').then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {

                dispatch({ type: 'EDIT_ARTICLE_SUCCESS', res });

            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: 'EDIT_ARTICLE_ERROR', res })
            }
        },
            error => {
                dispatch({ type: 'CODE_ERROR', error });
            }
        )
    }
}

export const deleteArticle = (data) => {
    return (dispatch) => {
        DeleteService(data, '/delete').then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {

                dispatch({ type: 'DELETE_ARTICLE_SUCCESS', res });

            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: 'DELETE_ARTICLE_ERROR', res })
            }
        },
            error => {
                dispatch({ type: 'CODE_ERROR', error });
            }
        )
    }
}
