import React, {useEffect, useRef} from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button,
  InputGroup,
  InputGroupAddon,
  FormCheckbox,
  FormInput
} from "shards-react";


export default function SidebarCategories({ formCategories, setter }) {
  const categoriesInput = useRef();
  const [categories, setCategories] = useState();

  const allCategories = useSelector(state => state.articles.categories);

  useEffect(() => {
    console.log(allCategories);
    setCategories(allCategories);
  }, [allCategories]);


  const toggleFormCategories = async e => {
    console.log(e.target.value);
    if (formCategories) {
      if (formCategories.includes(e.target.value)) {
        formCategories.splice(formCategories.indexOf(e.target.value), 1)
        e.target.checked = false
      } else {
        formCategories.push(e.target.value)
        e.target.checked = true
      }
    }else{
      formCategories = [];
      formCategories.push(e.target.value)
      e.target.checked = true
    }
    setter(formCategories)
  }

  useEffect(()=>{
    if(formCategories !== undefined){
      for(let label of categoriesInput.current.children){
        if(formCategories.includes(label.children[0].value)){
          label.children[0].checked = true
        }
      }
    }
  })

  return (
    <Card small className="mb-3">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Categories</h6>
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          <li className="px-3 pb-2 list-group-item" ref={categoriesInput}>
            {
              categories && categories.length != 0 &&
              categories.map((cat, index) => (
                <label key={index} className="mb-1 text-capitalize d-block">
                  <input
                    type="checkbox"
                    onChange={toggleFormCategories}
                    value={cat.value}
                  />
                  {" " + cat.label}
                </label>
              ))
            }
          </li>

          <ListGroupItem className="d-flex px-3">
            <InputGroup className="ml-auto">
              <FormInput placeholder="New category" />
              <InputGroupAddon type="append">
                <Button theme="white" className="px-2">
                  <i className="material-icons">add</i>
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  )
}
