import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ItemForm from "../../components/Auth/ItemForm";
import { useFormFields } from '../../helpers/hooksFormInput'
import $ from "jquery";
import 'jquery-validation';
import { UserLoginAction } from '../../store/actions/Auth/AuthActions';
import ActionBtn from '../../components/buttons/ActionBtn';


export default function LoginForm(props) {

    const [is_loading, setIsLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    if (localStorage.getItem('admin-token')) {
        props.props.history.push('/blog-overview');
    }
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: ""
    });


    const TogglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const dispatch = useDispatch();

    const authResponse = useSelector(state => state.userAuth.authResponse);


    const handleLogin = (e) => {
        if ($("#form-login").valid()) {
            setIsLoading(true)

            dispatch(UserLoginAction(fields, props.props, '/login'))
        }
    }

    return (
        <div className="row mt-3">
            <div className="col-md-6 col-lg-8 d-none d-sm-block d-md-none d-lg-block">
                <div className="page-image">
                    <img
                        src="/assets/images/login-illustration.svg"
                        alt="Dadupa Connect"
                        className='w-75 m-auto d-block'
                    />
                </div>
            </div>
            <div className="col-md-12 col-lg-4 d-flex align-items-center">
                <div className="form-wrapper w-100">
                    {authResponse.success === false &&
                        <div id="authErr" className="alert alert-danger">{authResponse.error}</div>
                    }
                    <form id="form-login" onSubmit={e => { e.preventDefault(); handleLogin(e) }} className="form-login">
                        <h3 className="form-title">Welcome team!</h3>
                        <div className="form-inputs">
                            <div className="input-row">
                                <ItemForm type="email" name="email" value={fields.email}
                                    onChange={handleFieldChange}
                                    placeholder="Type your email addresse"
                                    margin="normal"
                                    variant="outlined"
                                    id="email"
                                    className="wizard-required"
                                    required />
                            </div>
                            <div className="input-row">
                                <ItemForm id="password" name="password"
                                    placeholder='password'
                                    className="input-password"
                                    type={passwordShown ? "text" : "password"}
                                    onChange={handleFieldChange}
                                    required />
                                <span toggle="#password-field" onClick={TogglePasswordVisiblity}
                                    className="uil uil-eye field-icon toggle-password"></span>
                            </div>
                        </div>
                        <div className="login-options">
                            <div className="remember-me pl-2">
                                <label className="container-checkbox">
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                    <span> remember_me</span>
                                </label>
                            </div>
                        </div>
                        <div className="form-submit">
                            <ActionBtn value="Login"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
