import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Form,
    FormInput,
    ListGroup,
    ListGroupItem,
    Button,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import SidebarCategories from "../../components/add-new-post/SidebarCategories";
import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";
import ReactQuill from "react-quill";
import { editArticle } from "../../store/actions/Articles/ArticlesActions";
import { UpdateService } from "../../services/Article/ArticleServices";
import axios from "axios";
import ReactPlayer from "react-player";


export default function ArticleFormUpdate({ article}) {
    const [title, setTitle] = useState()
    const [body, setBody] = useState(article.body)
    const [media, setMedia] = useState()
    const [media_type, setMediaType] = useState()
    const [visibility, setVisibility] = useState()
    const [status, setStatus] = useState()
    const [categories, setCategories] = useState(article.categories && article
      .categories.length > 0 ? article.categories : []);
    const hiddenFileInput = useRef(null);

    useEffect(() => {
      setTitle(article.title)
      setBody(article.body)
      setMedia(article.media)
      setMediaType(article.media_type)
      setStatus(article.status)
      setVisibility(article.visibility)
      setCategories(article.categories && article.categories.length > 0 ? article.categories : [])
    }, [article])

    const [files, setFiles] = useState([]);

    const dispatch = useDispatch();

    function handleUpload(e) {
      const newFiles = Array.from(e.target.files);
      setFiles(newFiles);

      const formDataa = new FormData();
      newFiles.forEach((file) => {
        formDataa.append('attachments[]', file);
        formDataa.append("media_section", "article");
        formDataa.append("media_type", checkFileType(file));
        formDataa.append("provider", "article");
        formDataa.append("visibility", 'public');
        formDataa.append("provider_id", article.id);
      });

      let url = `${process.env.REACT_APP_MEDIA_UPLOAD}` + '/user/upload';
      axios.defaults.withCredentials = false;

      axios.post(url, formDataa, {
          onUploadProgress: progressEvent => {
            if (progressEvent.loaded > 0) {
            }
          },
        })
        .then((response) => {
          const urls = response.data.urls;

          let data = {
            'media_link': urls,
            'article_id': article.id,
          }
          UpdateService(data, '/update-media');

        });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        let data = {
          id: article.id,
          title: title,
          body: body,
          media: media,
          media_type: media_type,
          status: status,
          visibility: visibility,
          categories: categories,
        }

        dispatch(editArticle(data));
    }

    const checkFileType = (file) => {
      if (file) {
        var fileName = file.name; // Or file.url if available

        // Get the file extension
        var fileExtension = fileName.split('.').pop().toLowerCase();

        // List of supported image extensions
        var imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];

        // List of supported document extensions
        var documentExtensions = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx',
          'csv', 'pdf'
        ];

        // List of supported video extensions
        var videoExtensions = ['mp4', 'm4v', 'mov', 'avi', 'flv', 'wmv',
          'mkv', 'webm'
        ];

        if (imageExtensions.indexOf(fileExtension) !== -1) {
          return 'image';
        } else if (documentExtensions.indexOf(fileExtension) !== -1) {
          return 'document';
        } else if (videoExtensions.indexOf(fileExtension) !== -1) {
          return 'video';
        } else {
          return 'image';
        }
      }
    }

    const getExtension = (file) => {
      if (
        /^(https?:\/\/)?((www\.)?youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]{11}/
        .test(file)) {
        return 'youtube';
      } else if (/^(https?:\/\/)?(www\.)?vimeo\.com\/\d+/.test(file)) {
        return 'vimeo';
      } else {
        return file.split('.').pop().toLowerCase();
      }
    };

    return (
        <Container fluid className="main-content-container px-4 pb-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Edit Article" subtitle="Articles" className="text-sm-left" />
            </Row>

            <Form onSubmit={handleSubmit} className="add-new-post">

                <Row>
                    {/* Editor */}
                    <Col lg="9" md="12">
                        <Card small className="mb-3">
                            <CardBody>
                                <FormInput onChange={e => setTitle(e.target.value)} value={title} size="lg" className="mb-3" placeholder="Your Post Title" />
                                {/* <input onChange={handleAttachements} id='fileUpload' name="attachments[]" type='file' multiple /> */}
                                <label
                                  htmlFor="file-upload"
                                  className="custom-file-upload"
                                >
                                  Upload Media
                                </label>
                                <input
                                style={{display: 'none'}}
                                  ref={hiddenFileInput}
                                  onChange={handleUpload}
                                  multiple
                                  id="file-upload"
                                  type="file"
                                />
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                  {
                                    article.media && article.media.map(item => (
                                    <div key={item} style={{ flex: `1 0 ${100/article.media.length}%` }}>
                                      <div className="col-md-12 input-row">
                                      {(function() {
                                            if(getExtension(item) == 'youtube'){
                                                return <ReactPlayer url={item} controls={true} />
                                            }else{
                                                if(getExtension(item) == 'vimeo'){
                                                    return <ReactPlayer url={item} controls={true} />
                                                }else{
                                                    if(getExtension(item) == 'mp4' || getExtension(item) == 'ogg'
                                                    || getExtension(item) == 'webm'|| getExtension(item) == 'x-msvideo'
                                                    || getExtension(item) == 'quicktime'
                                                    ){
                                                        return <ReactPlayer url = {item} controls = {true} />
                                                    }else if (getExtension(item) == 'doc' || getExtension(item) == 'docx'
                                                    || getExtension(item) == 'xls'|| getExtension(item) == 'xlsx'
                                                    || getExtension(item) == 'ppt' || getExtension(item) == 'pptx'
                                                    || getExtension(item) == 'csv' || getExtension(item) == 'pdf'
                                                    ){
                                                      return <div className="Doc-Wrap">
                                                              <a href="#!">
                                                                <div className="Doc-Icon"><span className="Doc-Type"></span><i className="uil uil-file-alt"></i></div>
                                                                <div className="Doc-Name"><i className="uil uil-paperclip"></i> </div>
                                                              </a>
                                                            </div>
                                                    } else {
                                                      return <img width="100%" height="300" src={item} alt="Project"/>
                                                    }
                                                }
                                            }
                                        })()}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <ReactQuill onChange={setBody} defaultValue={body} className="add-new-post__editor mb-1" />
                            </CardBody>
                        </Card>
                    </Col>

                    {/* Sidebar Widgets */}
                    <Col lg="3" md="12">
                        <Card small className="mb-3">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Actions</h6>
                            </CardHeader>

                            <CardBody className="p-0">
                                <ListGroup flush>
                                    <ListGroupItem className="p-3">
                                        <span className="d-flex align-items-center mb-2">
                                            <i className="material-icons mr-1">flag</i>
                                            <strong className="mr-1">Status:</strong>{" "}
                                            <select value={status} onChange={e => setStatus(e.target.value)} className="form-control custom-select">
                                                <option value="draft">Draft</option>
                                                <option value="published">Published</option>
                                            </select>
                                        </span>
                                        <span className="d-flex align-items-center mb-2">
                                            <i className="material-icons mr-1">visibility</i>
                                            <strong className="mr-1">Visibility:</strong>{" "}
                                            <select value={visibility} onChange={e => setVisibility(e.target.value)} className="form-control custom-select">
                                                <option value="private">Private</option>
                                                <option value="public">Public</option>
                                                <option value="shared">Shared</option>
                                            </select>
                                        </span>
                                    </ListGroupItem>
                                    <ListGroupItem className="d-flex px-3 border-0">
                                        <Button outline theme="accent" size="sm">
                                            <i className="material-icons">save</i> Save Draft
                                        </Button>
                                        <Button type="submit" theme="accent" size="sm" className="ml-auto">
                                            <i className="material-icons">file_copy</i> Publish
                                        </Button>
                                    </ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>

                        <SidebarCategories formCategories={categories} setter={setCategories} />
                    </Col>
                </Row>
            </Form>

        </Container>
    )
};
