import React from "react";

const inputStyles = {
    width: "100%",
    height: "40px",
    borderRadius: "30px",
    boxShadow: "0 0 10px 0 rgb(0 0 0 / 2 %)",
    border: "2px solid #fff",
    padding: "0 15px",
    fontSize: "12px",
    transition: "all .3s linear",
    backgroundColor: "#fff"
}

const ItemForm = ({ label, children, type = "text", ...otherProps }) => (
    <div className="mb-2">
        {type === "text" ? (
            <>
                <label>{label}</label>
                <input style={inputStyles} type={type} {...otherProps} />
            </>
        ) : (
            <>
                <label />
                <input style={inputStyles} type={type} {...otherProps} />
                {label}
            </>
        )}
    </div>
);

export default ItemForm;