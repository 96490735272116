import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "shards-react";

export default function ConfirmationModal({ open, setter, title, action }) {
    return (
        <Modal open={open}>
            <ModalHeader className="text-center">{title}</ModalHeader>
            <ModalFooter className="justify-content-center">
                <Button theme="secondary" onClick={()=>{setter(false)}}>Cancel</Button>
                {/* <Button onClick={()=>{action(); setter(false)}}>Confirm</Button> */}
                <Button onClick={()=>{action(); setter(false)}}>Confirm</Button>
            </ModalFooter>
        </Modal>
    );

}
