import axios from 'axios'
import httpService from '../../../services/HttpService';

const http = new httpService();

export const UserLoginAction = (credentials,props, url) =>
{
  return (dispatch)=>{
    dispatch({type:'RESTART_AUTH_RESPONSE'});
    dispatch({type:'LOADING'});
    dispatch({type:'LOADING_LOAD_USER'});
    http.postData(credentials, url).then((res)=>{
        if (res.success === true && res.hasOwnProperty('accessToken')) {
            localStorage.setItem('admin-token', 'Bearer ' + res.accessToken);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.accessToken;
            dispatch({type:'LOGIN_SUCCESS', res});

            setTimeout(() => {
                props.history.push("/blog-overview");
                dispatch({type:'RESTART_AUTH_RESPONSE'});
            }, 100);

        }else if(res.success===false){
            dispatch({type:'LOGIN_ERROR',res})
        }
    },
    error=>{
        dispatch({type:'CODE_ERROR',error});
    })
  }
}

export const UserLogOutAction = (history) =>
{
  return (dispatch)=>{
    dispatch({type:'RESTART_AUTH_RESPONSE'});
      http.postData.then((res)=>{
          if(res.success===true){
              dispatch({type:'LOGOUT_SUCCESS',res});

              localStorage.removeItem('admin-token');
              history.push("/login")
          }else if(res.success===false){
              dispatch({type:'LOGOUT_ERROR',res})
          }
      },
      error=>{
          dispatch({type:'CODE_ERROR',error});
      }
    )
  }
}


export const clearUserAuthState = () =>
{
  return (dispatch) =>
  {
    dispatch({type:'RESTART_AUTH_RESPONSE'});
  }

}


