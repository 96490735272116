const initState = {
    articles: [],
    categories: []
}


const ArticlesReducer = (state = initState, action) => {

    switch (action.type) {

        case 'LOADING_LOAD_ARTICLES':
            return {
                ...state,
                articles: [],
                loading: true
            }

        case 'LOAD_ARTICLES_SUCCESS':
            return {
                ...state,
                articles: action.res.articles,
                loading: false
            }

        case 'LOAD_CATEGORIES_SUCCESS':
            return {
                ...state,
                categories: action.res.categories,
                loading: false
            }

        case 'LOGIN_ADMIN_ERROR':
            return {
                ...state,
                articles: action.res,
            }

        default:
            return state

    }
}

export default ArticlesReducer;
