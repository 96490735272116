export default function() {
  return [
    {
      title: "Blog Dashboard",
      to: "/blog-overview",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },
    {
      title: "Articles",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/articles",
    },
    {
      title: "Add New Article",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/add-new-article",
    },
    {
      title: "Reports",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/reports",
    },
    {
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite",
    },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // }
  ];
}
