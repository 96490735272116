import { GetService } from "../../../services/Admin/AdminServices";
import HttpService from "../../../services/HttpService";


export const getReports = (data) =>{
    return (dispatch)=>{

        dispatch({type:'LOADING_LOAD_REPORTS'});

        GetService('/report').then((res)=>{

            if(res.hasOwnProperty('success') && res.success===true){

                dispatch({type:'LOAD_REPORTS_SUCCESS',res});

            }else if(res.hasOwnProperty('success') && res.success===false) {
                dispatch({type:'LOAD_REPORTS_ERROR',res})
            }
        },
        error => {
            dispatch({type:'CODE_ERROR',error});
        }
        )
    }
}


export const deleteBlockReport = (data) =>{
    return (dispatch)=>{

        const http = new HttpService();
        http.postData(data, data.url).then((res)=>{

            if(res.hasOwnProperty('success') && res.success === true){

                dispatch({type:'CONTENT_BLOCKED_SUCCESS', res});

            }else if(res.hasOwnProperty('success') && res.success === false) {
                dispatch({type:'CONTENT_BLOCKED_ERROR',res})
            }
        },
        error => {
            dispatch({type:'CODE_ERROR',error});
        }
        )
    }
}
