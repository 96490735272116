import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
import { EditUserAction } from "../../store/actions/User/UserActions";
import { useEffect } from "react";

function UserAccountDetails  ({user}) {

  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [description, setDescription] = useState();
  const [job, setJob] = useState();

  useEffect(() => {
    setUsername(user.name);
    setEmail(user.email);
    setAddress(user.address);
    setDescription(user.metaValue);
    setJob(user.jobTitle);
  }, [user])

  const TogglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let data = {
      name  : username,
      email : email,
      password : password,
      oldPassword: oldPassword,
      address  : address,
      description : description,
      job : job
    }

    dispatch(EditUserAction(data, '/user/' + user.id));
  }

  return(
  <Card small className="mb-4">
    <CardHeader className="border-bottom">
      < h6 className = "m-0" > Account Details </h6>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <Row form>
                {/* First Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feFirstName">First Name</label>
                  <FormInput
                    id="feFirstName"
                    placeholder="First Name"
                    value = {
                      username
                    }
                    onChange = {
                      e => setUsername(e.target.value)
                    }
                  />
                </Col>
                {/* Last Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feEmail">Email</label>
                  <FormInput
                    type="email"
                    id="feEmail"
                    placeholder="Email Address"
                    value = {
                      email
                    }
                    onChange = {
                      e => setEmail(e.target.value)
                    }
                    autoComplete="email"
                  />
                </Col>
              </Row>
              <Row form>
                {/* Email */}
                <Col md="6" className="form-group">
                  <label htmlFor="fePassword">Old Password</label>
                  <FormInput
                    type = {
                      passwordShown ? "text" : "password"
                    }
                    id="fePassword"
                    placeholder="Old Password"
                    defaultValue=''
                    onChange = {
                      e => setOldPassword(e.target.value)
                    }
                  />
                    <span toggle="#password-field" onClick={TogglePasswordVisiblity}
                      className="uil uil-eye field-icon toggle-password"></span>
                </Col>
                {/* Password */}
                <Col md="6" className="form-group">
                  <label htmlFor="fePassword">Password</label>
                  <FormInput
                    type = {
                      passwordShown ? "text" : "password"
                    }
                    id="fePassword"
                    placeholder="Password"
                    defaultValue=''
                    onChange = {
                      e => setPassword(e.target.value)
                    }
                  />
                    <span toggle="#password-field" onClick={TogglePasswordVisiblity}
                      className="uil uil-eye field-icon toggle-password"></span>
                </Col>
              </Row>
              <FormGroup>
                <label htmlFor="feAddress">Address</label>
                <FormInput
                  id="feAddress"
                  placeholder="Address"
                  value = {address}
                  onChange = {
                    e => setAddress(e.target.value)
                  }
                />
              </FormGroup>
              <Row form>
                {/* City */}
                {/* <Col md="6" className="form-group">
                  <label htmlFor="feCity">City</label>
                  <FormInput
                    id="feCity"
                    placeholder="City"
                    onChange={() => {}}
                  />
                </Col> */}
                {/* State */}
                <Col md="4" className="form-group">
                  <label htmlFor="feInputState">State</label>
                  <FormSelect id="feInputState" value={job} onChange={e => setJob(e.target.value)}>
                    <option>Choose...</option>
                    <option name='author'>Author</option>
                    <option name='economiste'>Economiste</option>
                    <option name='auditeur'>Auditeur</option>
                    <option name='journaliste'>Journaliste</option>
                    <option name='expert-contenu'>Expert en contenu entrepreneurial </option>
                    <option name='chroniqueur'>Chroniqueur entrepreneurial </option>
                  </FormSelect>
                </Col>
                {/* Zip Code */}
                {/* <Col md="2" className="form-group">
                  <label htmlFor="feZipCode">Zip</label>
                  <FormInput
                    id="feZipCode"
                    placeholder="Zip"
                    onChange={() => {}}
                  />
                </Col> */}
              </Row>
              <Row form>
                {/* Description */}
                <Col md="12" className="form-group">
                  <label htmlFor="feDescription">Description</label>
                  <FormTextarea id="feDescription" defaultValue={description} rows="5" onChange={e => setDescription(e.target.value)} />
                </Col>
              </Row>
              <Button theme="accent">Update Account</Button>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>
  )
};

export default UserAccountDetails;
