import {combineReducers} from 'redux'
import UserAuthReducer from './Auth/UserAuthReducer'
import ReportReducer from './Report/ReportReducer'
import ArticlesReducer from './Articles/ArticlesReducer'
import ArticleReducer from './Articles/ArticleReducer'
import UserReducer from './User/UserReducer'

const RootReducer = combineReducers({
  userAuth: UserAuthReducer,
  user: UserReducer,
  reports: ReportReducer,
  articles: ArticlesReducer,
  article: ArticleReducer,
})

export default RootReducer

