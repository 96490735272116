import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import PageTitle from "../components/common/PageTitle";
// import ArticlesListSkeleton from "../components/skeletons/ArticlesListSkeleton";
import { getArticles } from "../store/actions/Articles/ArticlesActions";
import ArticlesTable from "../views/Articles/ArticlesTable";

export default function ArticlesList() {
    const dispatch = useDispatch();
    const articles = useSelector(state => state.articles.articles);
    const loading = useSelector(state => state.articles.loading);

    useEffect(() => {
        dispatch(getArticles());
    }, [dispatch]);

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Articles List" subtitle="Articles" className="text-sm-left" />
            </Row>

            <Row>
                <Col>
                    <Card small className="mb-4 overflow-hidden">
                        <CardBody className="p-0 pb-3">
                            {
                                <ArticlesTable articles={articles} />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </Container>
    )
}
