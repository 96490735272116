import React, { useRef } from "react";
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Progress
} from "shards-react";
import { useDispatch } from "react-redux";
import { UploadLogoAction } from "../../store/actions/Media/MediaAction";

function UserDetails ({userDetails}) {
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();

  const selectFile = (e) => {
    dispatch(UploadLogoAction(userDetails.id, e.target.files[0], 'admin', 'avatar',
      '/user/upload'));
  };

  return (
  <Card small className="mb-4 pt-3">
    <CardHeader className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        <img
          className="rounded-circle"
          src={userDetails.avatar}
          alt={userDetails.name}
          width="110"
        />
        < input type = "file"
          id = "imageUpload"
          name = "avatar"
          style={{display: 'none'}}
          accept = ".png, .jpg, .jpeg"
          ref = {
              hiddenFileInput
          }
          onChange = {
              selectFile
          }
        />

      </div>
      <label htmlFor="imageUpload" style={{ cursor: "pointer" }}>
            <i className="material-icons mr-1">edit</i>
      </label>
      <h4 className="mb-0">{userDetails.name}</h4>
      <span className="text-muted d-block mb-2">{userDetails.jobTitle}</span>
      <Button pill outline size="sm" className="mb-2">
        <i className="material-icons mr-1">person_add</i> Follow
      </Button>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="px-4">
        <div className="progress-wrapper">
          <strong className="text-muted d-block mb-2">
            {userDetails.performanceReportTitle}
          </strong>
          <Progress
            className="progress-sm"
            value={userDetails.performanceReportValue}
          >
            <span className="progress-value">
              {userDetails.performanceReportValue}%
            </span>
          </Progress>
        </div>
      </ListGroupItem>
      <ListGroupItem className="p-4">
        <strong className="text-muted d-block mb-2">
          {userDetails.metaTitle}
        </strong>
        <span>{userDetails.metaValue}</span>
      </ListGroupItem>
    </ListGroup>
  </Card>
  )
  };

export default UserDetails;
